<template>
  <div class="wraper flexCloumnSingleCenter sys_menu">
  wsad
   </div>
</template>

<script>
import api from '@/api/api';

export default {
  name: 'Menu',
  data() {
    return {
      visible: false,
      isShowModal: false,
      modalTitle: '添加',
      formData: {
        is_show:'1',
      },
      defaultProps: {
        label: 'title',
        value: 'id',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      //table
      tabMapOptions: [{
        label: '菜单列表',
        key: 'CN'
      }, ],
      activeName: 'CN',
      tableData: [],
      iconGroup: [
        'el-icon-setting', 'el-icon-user', 'el-icon-help',
        'el-icon-camera', 'el-icon-video-camera', 'el-icon-bell',
        'el-icon-s-cooperation', 'el-icon-s-order', 'el-icon-s-operation',
        'el-icon-s-flag', 'el-icon-s-claim', 'el-icon-s-custom',
        'el-icon-s-opportunity', 'el-icon-s-data', 'el-icon-s-check',
        'el-icon-s-grid', 'el-icon-menu', 'el-icon-share',
        'el-icon-refresh', 'el-icon-finished',
        'el-icon-rank', 'el-icon-view', 'el-icon-date',
        'el-icon-edit-outline', 'el-icon-folder-opened', 'el-icon-folder-checked',
        'el-icon-tickets', 'el-icon-document-copy', 'el-icon-document',
        'el-icon-printer', 'el-icon-paperclip', 'el-icon-suitcase',
        'el-icon-pie-chart', 'el-icon-data-line', 'el-icon-collection-tag',
        'el-icon-bank-card', 'el-icon-price-tag', 'el-icon-guide',
        'el-icon-full-screen', 'el-icon-discover', 'el-icon-location-information',
      ],
      rules: {
        title: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }, ],
      },
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
    }
  },
  watch: {
    'formData.pid'() {
      if (this.$refs.firstCascader) {
        this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
  }
}
</script>
<style scoped lang="less">
.sys_menu{
  padding-bottom: 10px;
}

</style>